@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .spin-button-none::-webkit-outer-spin-button,
  .spin-button-none::-webkit-inner-spin-button {
    appearance: none;
  }
}
